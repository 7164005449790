<template lang="pug">
.menu.container
	.flex.justify-between.items-center
		.menu__title

		.flex.justify-between.items-center
			FilterMultiSelectWithSearch(
				:name="filters.stores.name"
				:items="filters.stores.values"
				:storageIsFull="storageIsFull"
				:checkedStores="checkedStores.length"
				is-tree
				has-select-all
				has-search
				isMainPage
				search-placeholder="Поиск запроса"
				tooltip-placement="bottom-end"
				@select-all="selectAllStores(true)"
				@clear-all="selectAllStores(false)"
				@select-item="isSelectDirty = true"
				@hide="getStoresFilters()"
			)
			filters(
				name="Category"
				:items="filters.categories"
				@update="getCategoriesFilters"
				tree
			)
			FilterMultiSelectWithSearch(
				:class="{'disabled': !isCategoriesChecked || !filters.brands.values.length }"
				class="brand-filter"
				:name="filters.brands.name"
				:items="filters.brands.values"
				:storageIsFull="storageIsFull"
				:checkedStore="checkedBrands"
				:isMultiTree="false"
				is-tree
				has-select-all
				has-search
				isMainPage
				search-placeholder="Поиск бренда"
				tooltip-placement="bottom-end"
				@select-all="selectAllBrands(true)"
				@clear-all="selectAllBrands(false)"
				@select-item="isSelectDirty = true"
			)
			filters(
				name="Location"
				:items="filters.regions"
			)
			SeasonalFilter(
				name="isSeasonalFilter.name"
				v-model="filters.seasonal.values"
			)
			FilterMultiSelectWithSearch(
				class="sku-filter"
				:name="filters.sku.name"
				:items="formattedSkuValues"
        :checkedStore="checkedSku"
				has-search
				has-select-all
        is-server-search
        search-placeholder="Поиск по SKU"
        tooltip-placement="bottom-end"
        :is-items-loading="isSkuItemsLoading"
        emptyLabel="Для получения данных по фильтру требуется выбрать основные фильтры"
        @update:search="debouncedFetchSkuFilters($event)"
        @select-all="selectAllSku(true)"
        @clear-all="selectAllSku(false)"
        @select-item="selectSku"
        @intersect="handleSkuFilterLazyLoading"
			)

			.accuracy__date.flex.items-center.ml-20
				DatePicker(
					is-range
					v-model="rangeDates"
					:max-date="lastDay"
					:min-date="startingDate"
				)
					template(v-slot="{ inputValue, inputEvents, togglePopover }")
						.date(@click="togglePopover")
							calendar-blank.mr-4(:size="18")
							span {{ formattedDateFrom }}
							span(v-if="isRange") - {{ formattedDateTo }}
				DatePicker(
					v-if="false"
					is-range
					v-model="rangeDatesSecond"
					:max-date="lastDay"
					:min-date="startingDate"
				)
					template(v-slot="{ inputValue, inputEvents, togglePopover }")
						.date(@click="togglePopover")
							span(v-if="!rangeDatesSecond.start") Сравнить
							template(v-else)
								calendar-blank.mr-4(:size="18")
								span {{ formattedSecondDateFrom }}
								span(v-if="isRangeSecond") - {{ formattedSecondDateTo }}

			button.apply(
				@click="clearFilters"
			) Clear

			button.apply(
				:disabled="!isCheckedFull"
				@click="updateResults"
			) Apply filter

			span.separator

			.flex.justify-between.items-center
				Export.dashboard__export(
					type='white'
					@click="exportHandler"
				)

</template>

<script>

import {debounce} from "lodash";

const YESTERDAY = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
const STARTING_DATE = new Date(2022, 0, 1)
// const HALF_YEAR_AGO = new Date(Date.now() - 24 * 60 * 60 * 182 * 1000)

import {mapState, mapActions, mapMutations} from 'vuex'
import { DatePicker } from 'v-calendar'
import CalendarBlank from 'vue-material-design-icons/CalendarBlank.vue'
import ConfigInLocalStorage from '@/helpers/LocalStorage/Config'
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import Filters from './Filter.vue'
import SeasonalFilter from "@/components/Nestle/SeasonalFilter/SeasonalFilter.vue";
import FilterMultiSelectWithSearch from './FilterMultiSelectWithSearch.vue'
import Export from '@/components/Nestle/Export/Export.vue'
import { ExportSpreadsheet } from '@/helpers/ExportSpreadsheet'
import api from "@/api";

export default {
	components: {
		SeasonalFilter,
		ConfigInLocalStorage,
		FilterInLocalStorage,
		CalendarBlank,
		DatePicker,
		Filters,
		FilterMultiSelectWithSearch,
		Export
	},
		name: 'GlobalFilter',
	data() {
		return {
      isSkuItemsLoading: false,
			checkedFilters: {
				stores: [],
				categories: [],
				brands: [],
				regions: [],
			},
			filters: {
				stores: {
					name: 'stores',
					values: [],
				},
				categories: {
					name: 'categories',
					values: [],
				},
				brands: {
					name: 'brands',
					values: [],
				},
				regions: {
					name: 'regions',
					values: [],
				},
				sku: {
					name: 'Выберите SKU',
					values: [],
				},
				seasonal: {
					values: [
						{ name: "Including Seasonal Products", value: "0", checked: true },
						{ name: "Only Seasonal Products", value: "1", checked: false },
						{ name: "Exclude Seasonal Products", value: "2", checked: false },
					]
				}
			},
			rangeDates: {
				start: null,
				end: null,
			},
			rangeDatesSecond: {
				start: null,
				end: null,
			},
      skuFilterLimit: 30,
      skuFilterOffset: 0,
      skuFilterTotal: 0,
			isFetchProcessing: true,
			activeButton: false,
			isMounted: false,
			storageIsFull: false,
		}
	},
	computed: {
		...mapState({
			stores: ({ globalFilter }) => globalFilter.stores,
			categories: ({ globalFilter }) => globalFilter.categories,
			brands: ({ globalFilter }) => globalFilter.brands,
			regions: ({ globalFilter }) => globalFilter.regions,
			secondPeriod: ({ globalFilter }) => globalFilter.secondPeriod,
      sku: ({ globalFilter }) => globalFilter.sku,
			dashboardActiveTab: ({ globalFilter }) => globalFilter.dashboardActiveTab,
			activePage: ({ brandAndSku }) => brandAndSku.activePage,
		}),
		errorsAndFixesUrl() {
			return 'https://docs.google.com/spreadsheets/d/1TiA3t-cb_d0et9J6sfLje5nvcFB_w3WLMcfS7BhEfeQ/edit?usp=sharing'
		},
		additionalLink() {
			let result = null
			if (this.$route.name === 'Nestle.KPIDashboard') {
				result = {
					title: 'Market categories',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=0',
				}
			}
			if (this.$route.name === 'Nestle.TaxonomyPlacement') {
				result = {
					title: 'Taxonomy target',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=1929339347',
				}
			}
			if (this.$route.name === 'Nestle.ContentIntegrity') {
				result = {
					title: 'Content rules',
					url: 'https://docs.google.com/spreadsheets/d/19H8zUA3iV529ilHaKLZcbwWjES5PSCJ5pIIWmeLPrrQ/edit#gid=917782129',
				}
			}
			return result
		},
		isSeasonalFilter() {
			return this.filters.seasonal.values.find(item => item.checked);
		},
		isRange() {
			return Date.parse(this.rangeDates.start) !== Date.parse(this.rangeDates.end);
		},
		isRangeSecond() {
			return Date.parse(this.rangeDatesSecond.start) !== Date.parse(this.rangeDatesSecond.end);
		},
		formattedDateFrom() {
			return this.$utils.format(this.rangeDates.start, 'dd.MM.yyyy')
		},
		formattedDateTo() {
			return this.$utils.format(this.rangeDates.end, 'dd.MM.yyyy')
		},
		formattedSecondDateTo() {
			return this.$utils.format(this.rangeDatesSecond.end, 'dd.MM.yyyy')
		},
		formattedSecondDateFrom() {
			return this.$utils.format(this.rangeDatesSecond.start, 'dd.MM.yyyy')
		},
    formattedSkuValues() {
      return [...this.filters.sku.values.filter(item => item.checked), ...this.filters.sku.values.filter(item => !item.checked)]
    },
		lastDay() {
			return YESTERDAY
		},
		startingDate() {
			return STARTING_DATE
		},
		isCheckedFull() {
			return !!(
				this.isStoresChecked &&
				this.isCategoriesChecked &&
				this.isBrandsChecked &&
				this.isRegionsChecked
			)
		},
    checkedSku() {
      return this.filters.sku.values.filter(item => item.checked);
    },
		checkedStores() {
			return this.filters.stores.values.filter(item => item.checked)
		},
		checkedBrands() {
			return this.filters.brands.values.filter(item => item.checked);
		},
		isStoresChecked() {
			return this.checkedStores.map(item => item.id).join(',')
		},
		isCategoriesChecked() {
			const arrayOfValues = Object.values(this.filters.categories.values)
			const result = [].concat(...arrayOfValues).filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
		isBrandsChecked() {
			const result =  this.filters.brands.values.filter(item => item.checked)
			return [...new Set(result.map(item => item.parentId))].join(',');
		},
		isSubBrandsChecked() {
			const result = this.filters.brands.values.filter(item => item.checked)
			return result.map(item => item.name).join(',');
		},
		isRegionsChecked() {
			const result =  this.filters.regions.values.filter(item => item.checked)
			return result.map(item => item.id).join(',')
		},
    isSkuChecked() {
      const result = this.filters.sku.values.filter(item => item.checked);
      return result.map(item => item.id).join(',');
    },
		isStoresLessLimits() {
			const storeLimits = ConfigInLocalStorage.getLimits('stores')
			return this.checkedStores.length <= storeLimits
		},
		isMainPage() {
			const pattern = ['', '/ferrero', '/ferrero/']
			return pattern.includes(this.$route.path)
		},
	},
	created() {
		const config = {
			limits: {
				stores: 5,
			}
		};
		ConfigInLocalStorage.set(config);
	},
	mounted() {
		this.fetch()
	},
	methods: {
		...mapActions('globalFilter', [
      'fetchSku',
			'fetchStores',
			'fetchCategories',
			'fetchBrands',
			'fetchRegions',
			'saveSecondPeriod',
		]),

		...mapActions('brandAndSku', [
				'getExportBrands',
				'getExportSKUBrand'
		]),

    ...mapMutations('globalFilter', [
        'setSelectedSku',
        'clearSelectedSku',
    ]),

    async handleSkuFilterLazyLoading() {
      if (this.isSkuItemsLoading) {
        return;
      }

      if (this.skuFilterTotal && this.skuFilterTotal === this.filters.sku.values.length) {
        return;
      }

      this.isSkuItemsLoading = true;

      const params = {
        brands: this.isBrandsChecked.split(','),
        stores: this.isStoresChecked.split(','),
        categories: this.isCategoriesChecked.split(','),
        regions: this.isRegionsChecked.split(','),
        subBrands: this.isSubBrandsChecked.split(','),
        limit: this.skuFilterLimit,
        offset: this.skuFilterOffset,
      };

      const savedFilters = FilterInLocalStorage.get();

      if (!this.filters.sku.values.length && savedFilters.sku) {
        params.selectedSku = savedFilters.sku.split(',').map((item) => Number(item));
      }

      try {
        const response = await api.nestle.getSku(params);

        if (savedFilters && !this.filters.sku.values.length && params.selectedSku) {
          this.filters.sku.values = response.sku.map((skuItem) => ({
            ...skuItem,
            checked: params.selectedSku.includes(skuItem.id),
          }));
        } else {
          this.filters.sku.values = [...this.filters.sku.values, ...response.sku];
        }
        this.skuFilterTotal = response.count;
        this.skuFilterOffset = this.skuFilterLimit + this.skuFilterOffset;
      } catch (e) {
        console.log(e);
      }
      finally {
        this.isSkuItemsLoading = false;
      }
    },

    selectSku(event) {
      this.filters.sku.values = this.filters.sku.values.map((item) => ({
        ...item,
        checked: item.id === event ? !item.checked : !!item.checked,
      }));

      this.setSelectedSku(event);
    },

		async exportContentIntegrity() {
			const params = this.getParams();

			ExportSpreadsheet(
				this.$api.nestle.getExportContentIntegrity,
				params,
				'export-contentIntegrity'
			)
				.catch(err => console.error(err));
		},
		async exportPortfolioAvailability() {
			const params = this.getParams();

			ExportSpreadsheet(
				this.$api.nestle.getExportPortfolioAvailability,
				params,
				'export-portfolioAvailability'
			)
				.catch(err => console.error(err));
		},
		async exportRatingsAndReviews() {
			const params = this.getParams();

			ExportSpreadsheet(
				this.$api.nestle.getExportRatingsAndReviews,
				params,
				'export-ratingsAndReviews'
			)
				.catch(err => console.error(err));
		},
		async exportImageAccuracy() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportImageAccuracy(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image],
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-imageAccuracy.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},
		async exportShareOfSearch() {
			const params = this.getParams();

			try {
				const image = await this.$api.nestle.getExportShareOfSearch(params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image],
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', 'export-shareOfSearch.xlsx')
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},
		async exportDashboard() {
			const params = this.getParams();
			const dashboardActiveTab = this.dashboardActiveTab;

			try {
				const image = await this.$api.nestle.getExportDashboard(dashboardActiveTab, params)
				const fileURL = window.URL.createObjectURL(
					new Blob(
						[image],
						{ type: "application/ vnd.openxmlformats - officedocument.spreadsheetml.sheet; charset = UTF-8" }
					)
				)
				const fileLink = document.createElement('a')

				fileLink.href = fileURL
				fileLink.setAttribute('download', `export-dashboard-${dashboardActiveTab}.xlsx`)
				document.body.appendChild(fileLink)
				fileLink.click()
			} catch (error) {
				console.log(error)
			}
		},

		async exportHandler() {
			if (this.$route.name === 'Nestle.ContentIntegrity') {
				await this.exportContentIntegrity();
			} else if (this.$route.name === 'Nestle.ImageAccuracy') {
				await this.exportImageAccuracy();
			} else if (this.$route.name === 'Nestle.PortfolioAvailability') {
				await this.exportPortfolioAvailability()
			} else if (this.$route.name === 'Nestle.RatingsAndReviews') {
				await this.exportRatingsAndReviews()
			} else if (this.$route.name === 'Nestle.ShareOfSearch') {
				await this.exportShareOfSearch()
			} else if (this.$route.name === 'Nestle.BrandsAndSKU.Brand') {
				await this.getExportBrands(this.getParams())
			} else if (this.$route.name === 'Nestle.BrandsAndSKU.SKU') {
				await this.getExportSKUBrand(this.getParams())
			} else if (this.$route.name === 'Nestle.KPIDashboard') {
				await this.exportDashboard();
			}
		},

		// TODO вынести в модель
		getStorage() {
			const defaultStorage = {
				stores: '',
				categories: '',
				brands: '',
				subBrands: '',
				regions: '',
        excludeSku: '',
				seasonProducts: '',
				dateFrom: '',
				dateTo: '',
			}
			return FilterInLocalStorage.get() || defaultStorage;
		},
		normalizeStorage({
			stores,
			categories,
			brands,
			regions,
        sku,
		}) {
			return {
				stores: stores.split(',').map(Number) || [],
				categories: categories.split(',').map(Number) || [],
				brands: brands.split(',').map(Number) || [],
				regions: regions.split(',').map(Number) || [],
        sku: sku.split(',') || [],
			}
		},
		// TODO check it
		updateState() {
			const brands = this.filters.brands.values
			this.checkedFilters.brands.splice(0)
			brands.forEach(brand => {
				if(brand.checked) {
					this.checkedFilters.brands.push(Number(brand.id))
				}
			})
		},
		async clearStoresFilter() {
			await this.fetchStores({ categories: this.isCategoriesChecked })
			this.filters.stores.values = this.prepareStores();
		},
		async getCategoriesFilters() {
			if (this.isCategoriesChecked) {
				if (this.isStoresChecked) {
					this.getBrands()
				} else {
					this.clearStoresFilter()

					const categories =  this.filters.categories.values
					this.checkedFilters.categories = []

					for (let key in categories) {
						categories[key].forEach(category => {
							if(category.checked) {
								this.checkedFilters.categories.push(Number(category.id))
							}
						})
					}
				}
			} else {
				this.clearStoresFilter()
				this.filters.brands.values = []
			}
		},
		getStoresFilters() {
			this.checkedFilters.stores = this.isStoresChecked
			if (this.isCategoriesChecked) {
				if (this.isStoresChecked) {
					this.getBrands()
				} else {
					this.fetchStores()
					this.getCategories()
					this.filters.brands.values = []
				}
			} else {
				this.getCategories()
			}
		},
		async getBrands() {
			if(this.isStoresChecked && this.isCategoriesChecked) {
				const params = {
					stores: this.isStoresChecked,
					categories: this.isCategoriesChecked,
				}

				await this.fetchBrands(params)
				this.filters.brands.values = this.brands
				this.checkAllItems(this.filters.brands.values)
			}
		},
		initDates(dates) {
			const initialData = {
				dateFrom: YESTERDAY,
				dateTo: YESTERDAY,
			}

			const LSDateFrom = FilterInLocalStorage.getByName('dateFrom')
			const LSDateTo = FilterInLocalStorage.getByName('dateTo')

			// TODO Оптимизировать
			if (!LSDateFrom) {
				FilterInLocalStorage.add('dateFrom', initialData.dateFrom)
				dates.start = YESTERDAY
			} else {
				dates.start = this.$utils.parseISO(LSDateFrom)
			}

			if (!LSDateTo) {
				FilterInLocalStorage.add('dateTo', initialData.dateTo)
				dates.end = YESTERDAY
			} else {
				dates.end = this.$utils.parseISO(LSDateTo)
			}
		},

		prepareArray(checkedArray, arrayOfData) {
			checkedArray.forEach((checkedItem) => {
				arrayOfData.forEach((item) => {
					if (Number(item.id) === Number(checkedItem)) {
						this.$set(item, 'checked', true )
					}
				})
			})

			return arrayOfData
		},

		prepareCategories(checkedArray, arrayOfData) {
			checkedArray.forEach((checkedItem) => {
				Object.values(arrayOfData).forEach((item) => {
					item.forEach(el => {
						if (Number(el.id) === Number(checkedItem)) {
							this.$set(el, 'checked', true)
						}
					})
				})
			})
			return arrayOfData
		},

		checkFulledStorage(storage) {
			return Object.prototype.hasOwnProperty.call(storage, 'stores') &&
				Object.prototype.hasOwnProperty.call(storage, 'categories') &&
				Object.prototype.hasOwnProperty.call(storage, 'brands') &&
				Object.prototype.hasOwnProperty.call(storage, 'regions') &&
				!!storage?.stores.length &&
				!!storage?.categories.length &&
				!!storage?.brands.length &&
				!!storage?.regions.length
		},

		checkAllItems(arr) {
			Object.values(arr).forEach(item => this.$set(item, 'checked', true))
		},
		checkItemsByName(arr, arrayOfNames) {
			arrayOfNames.forEach((name) => {
				Object.values(arr).forEach((item) => {
					if (name === item.name) {
						this.$set(item, 'checked', true)
					}
				})
			})
		},

		selectAllBrands(selectValue) {
			this.filters.brands.values.forEach(item => {
				item.checked = selectValue;
			})
		},

		selectAllStores(selectValue) {
			this.filters.stores.values.forEach((item, i) => {
					if (i > 4 && selectValue && !this.isMainPage) return;
					item.checked = selectValue;
			})
		},

    selectAllSku(selectValue) {
      this.filters.sku.values.forEach(item => {
        item.checked = selectValue;
      });

      if (!selectValue) this.clearSelectedSku();
    },

		prepareStores() {
			return this.stores.reduce((storeArr, group) => {
				const store = {
					groupName: group.groupName,
					checked: false,
				}

				for (const [key, subGroup] of Object.entries(group.subGroups)) {
					store.parentId = key;
					store.parentName = subGroup.groupName;

					subGroup.markets.forEach(market => {
						store.id = market.id
						store.name = market.name

						storeArr.push({...store})
					})
				}

				return storeArr;

			}, [])
		},

    async fetchSkuFilters(search) {
      this.isSkuItemsLoading = true;

      await this.fetchSku({
        brands: this.isBrandsChecked.split(','),
        stores: this.isStoresChecked.split(','),
        categories: this.isCategoriesChecked.split(','),
        regions: this.isRegionsChecked.split(','),
        subBrands: this.isSubBrandsChecked.split(','),
        search,
      });

      this.filters.sku.values = [...this.sku];

      this.isSkuItemsLoading = false;
    },

    debouncedFetchSkuFilters: debounce(function(search) {
      this.isSkuItemsLoading = true;

      this.fetchSku({
        brands: this.isBrandsChecked.split(','),
        stores: this.isStoresChecked.split(','),
        categories: this.isCategoriesChecked.split(','),
        regions: this.isRegionsChecked.split(','),
        subBrands: this.isSubBrandsChecked.split(','),
        search,
      }).then(() => {
        this.filters.sku.values = [...this.sku];
        this.isSkuItemsLoading = false;
      });
    }, 800),

		async fetch() {
			await this.fetchStores({ categories: this.isCategoriesChecked })
			await this.getCategories()

			await this.fetchRegions()

			const rawStorage = this.getStorage()
			this.storageIsFull = this.checkFulledStorage(rawStorage)

			// При 1й загрузке проверяем, есть ли сохраненные значения в LocalStorage
			// Если есть, подгружаем фильтры с учетом LocalStorage
			if (this.storageIsFull) {
				const storage = this.normalizeStorage(rawStorage)
				this.filters.stores.values = this.prepareArray(storage.stores, this.prepareStores())
				this.filters.regions.values = this.prepareArray(storage.regions, this.regions)
        this.filters.sku.value = this.prepareArray(storage.sku, this.sku);

				const temporaryCategories = this.filters.categories.values
				this.filters.categories.values = this.prepareCategories(storage.categories, temporaryCategories)

				await this.getBrands()
				const temporaryBrands = this.filters.brands.values
				this.filters.brands.values = this.prepareArray(storage.brands, temporaryBrands)
			} else {
				this.filters.stores.values = this.prepareStores();
				this.filters.regions.values = this.regions
        await this.handleSkuFilterLazyLoading();

				const defaultCheckedRegions = ['Москва']
				this.checkItemsByName(this.filters.regions.values, defaultCheckedRegions)

				if (!this.storageIsFull && this.isMainPage) {
					this.checkAllItems(this.filters.stores.values)
				}
			}

			this.isMounted = true
			this.$emit('check-fulled-storage', this.storageIsFull)
		},
		async getCategories() {
			const newOnj = {}

			try {
				this.isFetchProcessing = true
				const filters = await this.$api.nestle.getParentCategories()
				await this.fetchCategories({ stores: this.isStoresChecked })

				filters.forEach(parentCategory => {
					this.categories.forEach(category => {
						category.filterId.forEach((item) => {
							if(item === +parentCategory.id) {
								if (!Object.prototype.hasOwnProperty.call(newOnj, parentCategory.name)) {
									newOnj[parentCategory.name] = []
								}
								newOnj[parentCategory.name].push({
									id: category.id,
									parentId: parentCategory.id,
									name: category.name,
									checked: category.checked || null,
									filterId: category.filterId,
								})
							}
						})
					})
				})

				const sortedParentCategories = Object.keys(newOnj)
					.sort()
					.reduce((obj, key) => {
						obj[key] = newOnj[key];
						return obj;
					}, {})

				this.filters.categories.values = sortedParentCategories
			} catch(error) {
				console.error(error)
			} finally {
				this.isFetchProcessing = false
			}
		},
		// TODO проверить и оптимизировать
		async clearFilters() {
			this.checkedFilters = {
				stores: [],
				categories: [],
				brands: [],
				regions: [],
			}

			this.filters = {
				stores: {
					name: 'stores',
					values: [],
				},
				categories: {
					name: 'categories',
					values: [],
				},
				brands: {
					name: 'brands',
					values: [],
				},
				regions: {
					name: 'regions',
					values: this.filters.regions.values,
				},
        sku: {
          name: 'Выберите SKU',
          values: [],
        },
        seasonal: {
          values: [
            { name: "Including Seasonal Products", value: "0", checked: true },
            { name: "Only Seasonal Products", value: "1", checked: false },
            { name: "Exclude Seasonal Products", value: "2", checked: false },
          ]
        }
			}

			this.fetch()

			FilterInLocalStorage.remove()

			FilterInLocalStorage.add('dateFrom', YESTERDAY)
			FilterInLocalStorage.add('dateTo', YESTERDAY)
			this.rangeDates = {
				start: YESTERDAY,
				end: YESTERDAY
			}

			this.activeButton = true
		},

		getParams() {
			const rawStorage = this.getStorage()

			const result = {
				stores: rawStorage.stores,
				categories: rawStorage.categories,
				brands: rawStorage.brands,
				subBrands: rawStorage.subBrands,
        excludeSku: rawStorage.sku,
				regions: rawStorage.regions,
				seasonProducts: rawStorage.seasonProducts,
				dateFrom: this.$utils.format(this.$utils.parseISO(rawStorage.dateFrom)) || null,
				dateTo: this.$utils.format(this.$utils.parseISO(rawStorage.dateTo)) || null,
			}
			return result
		},

		updateResults() {
			this.activeButton = false

			if (this.isMainPage && !this.isStoresLessLimits) {
				const params = {
					stores: this.isStoresChecked,
					categories: this.isCategoriesChecked,
					brands: this.isBrandsChecked,
					regions: this.isRegionsChecked,
					seasonProducts: this.isSeasonalFilter.value,
					subBrands: this.isSubBrandsChecked,
          excludeSku: this.isSkuChecked,
					dateFrom: this.$utils.format(this.rangeDates.start),
					dateTo: this.$utils.format(this.rangeDates.end),
				}

				if (this.rangeDatesSecond.start) {

					params.dateFrom = this.$utils.format(this.rangeDatesSecond.start)
					params.dateTo = this.$utils.format(this.rangeDatesSecond.end)

					this.saveSecondPeriod({
						from: params.dateFrom,
						to: params.dateTo,
					})
					this.$emit('update-filters', params, true)
				} else {
					FilterInLocalStorage.add('stores', this.isStoresChecked)
					FilterInLocalStorage.add('categories', this.isCategoriesChecked)
					FilterInLocalStorage.add('brands', this.isBrandsChecked)
					FilterInLocalStorage.add('subBrands', this.isSubBrandsChecked)
					FilterInLocalStorage.add('regions', this.isRegionsChecked)
          FilterInLocalStorage.add('sku', this.isSkuChecked);
					FilterInLocalStorage.add('seasonProducts', this.isSeasonalFilter.value);
					FilterInLocalStorage.add('dateFrom', this.rangeDates.start)

					this.$emit('update-filters', params)
				}

				this.$emit('filter-status', this.isCheckedFull)
			} else {
				FilterInLocalStorage.add('stores', this.isStoresChecked)
				FilterInLocalStorage.add('categories', this.isCategoriesChecked)
				FilterInLocalStorage.add('brands', this.isBrandsChecked)
				FilterInLocalStorage.add('subBrands', this.isSubBrandsChecked)
				FilterInLocalStorage.add('regions', this.isRegionsChecked)
        FilterInLocalStorage.add('sku', this.isSkuChecked);
				FilterInLocalStorage.add('seasonProducts', this.isSeasonalFilter.value);
				FilterInLocalStorage.add('subBrands', this.isSubBrandsChecked);

				FilterInLocalStorage.add('dateFrom', this.rangeDates.start)
				FilterInLocalStorage.add('dateTo', this.rangeDates.end)

				const params = this.getParams()

				if (this.rangeDatesSecond.start) {
					params.dateFrom = this.$utils.format(this.rangeDatesSecond.start)
					params.dateTo = this.$utils.format(this.rangeDatesSecond.end)

					this.saveSecondPeriod({
						from: params.dateFrom,
						to: params.dateTo,
					})
					this.$emit('update-filters', params, true)
				} else {
					this.$emit('update-filters', this.getParams())
				}

			}
		},

	},

	watch: {
		rangeDates: {
			immediate: true,
			handler(value) {
				if (!value.end && !value.start) {
					this.initDates(this.rangeDates)
				} else {
					FilterInLocalStorage.add('dateFrom', value.start)
					FilterInLocalStorage.add('dateTo', value.end)
				}
				this.activeButton = true
			}
		},
		rangeDatesSecond: {
			handler(value) {
				if (!value.end && !value.start) {
					this.initDates(this.rangeDatesSecond)
				}
			}
		},
    isBrandsChecked: {
      async handler(value) {
        if (value.length && this.isCheckedFull) {
          await this.handleSkuFilterLazyLoading();
        }
      }
    },
		'$route.path': {
			deep: true,
			immediate: true,
			handler() {
				this.$emit('update-filters', this.getParams())
			},
		},
	},

}
</script>

<style lang="scss" scoped>

.date {
	display: flex;
}

.menu {
	width: 100%;
	padding: 12px 24px;
	background-color: color(gray-800);

	&__title {
		min-height: 32px;
		color: color(white);
		font-size: 24px;
		line-height: 1.33;

		&.--nestle {
			width: 280px; // todo for demo only
		}
	}
	&__nav {
		width: 640px; // todo for demo only

		&-wrap {
			max-width: 450px;
		}
	}
	&__item {
		font-size: 12px;
		color: color(white);
	}
}


.accuracy {
	&__nav {
		width: 190px;
	}
	&__date {
		display: flex;
		align-items: baseline;
		flex-direction: column;
		color: color(white);
		cursor: pointer;
	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}
	&__chart {
		::v-deep .chart {
			height: 340px;
		}
	}
	&__product {
		width: 32%;
	}
}
.header {
	margin-bottom: 32px;

	&-right {
		margin-left: auto;
	}
}
.filter {
	&__list {
		max-height: 200px;
		overflow: auto;
	}

	&__label {
		margin-left: 10px;
		padding: 0 4px 0 10px;
		outline: 1px color(gray-400) solid;
		border-radius: 3px;
		color: color(gray-700);
		background-color: color(white);
		cursor: pointer;

		&-icon {
			margin-left: 4px;
		}
	}

	&__bottom {
		margin-top: 15px;
		padding-top: 5px;
		border-top: 1px solid color(gray-300);
	}
	&__clear {
		color: color(blue-bright);
		cursor: pointer;
	}
	&__apply {
		padding: 4px 10px 5px 10px;
		background-color: color(blue-bright);
		color: color(white);
		cursor: pointer;
		border-radius: 4px;
	}
}

select {
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus {
		outline: none;
	}
}

.apply {
	margin-left: 20px;
	border: 1px solid #ffffff;
	padding: 2px 5px;
	cursor: pointer;
	color: #fff;

	border-radius: 4px;

	border-radius: 3px;
	&:disabled {
		cursor: not-allowed;
		color: gray;
		background-color: lightgray;
		border-color: lightgray;
	}
	&:focus {
		border: 1px solid black;
		outline: inherit;
	}
}
.disabled {
	pointer-events: none;

	::v-deep.filter-button {
		color: gray;
	}
}
.additional-link {
	color: #ffffff;
	margin-right: 20px;
}
.separator {
		display: block;
		width: 1px;
		height: 27px;
		background-color: rgba(255, 255, 255, 0.4);
		margin-left: 24px;
		margin-right: 24px;
}

.brand-filter {
	margin-left: 8px;
}

.sku-filter {
	margin-left: 8px;

  ::v-deep .filter-button {
    border: 1px solid white;
    padding: 2px 4px;
    border-radius: 4px;
  }
}
</style>
